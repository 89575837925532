/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect, useState } from 'react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import useEmblaCarousel from 'embla-carousel-react';

/**
 *
 * @param {Array} list
 * @param {Object} cardCountByMediaQuery
 * @param {Object} emblaOptions
 * @param {Boolean} responsive
 * @returns
 */
function useCarousel(
  list,
  { emblaOptions = {}, responsive = false, activeMedia = 0 },
) {
  const [activeLists, setActiveLists] = useState(!responsive ? list : []);

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(activeMedia);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      ...emblaOptions,
    },
    [WheelGesturesPlugin()],
  );

  /** Navigation */
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const scrollTo = useCallback((index, jump = false) => {
    if (emblaApi) {
      emblaApi.scrollTo(index, jump);
    }
  }, [emblaApi]);

  /** Select */
  const onSelect = useCallback(() => {
    if (emblaApi) {
      setSelectedIndex(emblaApi.selectedScrollSnap());
      setPrevBtnEnabled(emblaApi.canScrollPrev());
      setNextBtnEnabled(emblaApi.canScrollNext());
    }
  }, [
    emblaApi,
    setSelectedIndex,
    setPrevBtnEnabled,
    setNextBtnEnabled,
  ]);

  useEffect(() => {
    if (emblaApi) {
      onSelect();
      setScrollSnaps(emblaApi.scrollSnapList());
      emblaApi.on('select', onSelect);
    }
  }, [emblaApi, setScrollSnaps, onSelect]);

  /** ReInit carousel if list type change */
  useEffect(() => {
    if (!emblaApi) return;
    setActiveLists(list);
    emblaApi.reInit();
  }, [emblaApi, list, activeLists]);

  const onInit = useCallback(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, onSelect, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onInit();
    emblaApi
      .on('select', onSelect)
      .on('reInit', onInit);
  }, [emblaApi, onInit, onSelect]);

  return {
    activeLists,
    setActiveLists,
    emblaRef,
    prevBtnEnabled,
    nextBtnEnabled,
    selectedIndex,
    scrollSnaps,
    scrollPrev,
    scrollNext,
    scrollTo,
  };
}

export default useCarousel;
