// Needed to generate srcSet for <img>
// Replace by next Image component when back end will provide image width & height
const srcSet = (url) => {
  const srcsSet = [];
  const deviceSizes = [375, 480, 608, 768, 1024, 1440, 1920, 2048, 3840];
  deviceSizes.forEach((deviceSize) => {
    srcsSet.push(`${url}?w=${deviceSize}&format=webp ${deviceSize}w`);
  });
  return srcsSet.join(',');
};

export default srcSet;
