import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';

// Utils
import { cn } from 'utils';

// Styles
import styles from './AccordionDynamicBlock.module.css';

// Hooks
import useResponsiveQueries from '../../../src/hooks/useResponsiveQueries';
import useAccordion from '../../../src/hooks/useAccordion';

// Components
import CarouselBlock from '../../atoms/CarouselBlock/CarouselBlock';
import Title from '../../atoms/Title/Title';
import Accordion from '../../atoms/Accordion/Accordion';

function AccordionDynamicBlock({
  title, subtitle, list, theme,
}) {
  const { indexExpanded, handleExpanded } = useAccordion(
    { defaultExpanded: 0, alwaysOneExpanded: true },
  );
  const { isTablet } = useResponsiveQueries();

  return (
    <div className={cn([styles.wrapper, styles[theme]])}>
      <div className={styles.container}>
        <div className={styles.faq}>
          <Title
            title={title}
            subtitle={subtitle}
            theme={theme}
            className={styles.title}
          />
          <div className={styles.questions}>
            {list?.length ? list.map(({ data: item }, index) => (
              <Accordion
                key={`${item?.title}${index}`}
                item={item}
                isExpanded={indexExpanded === index}
                handleExpanded={() => handleExpanded(index)}
                theme={theme}
              />
            )) : null}
          </div>
        </div>

        {list?.length ? list.map(({ data: item }, index) => (
          <AnimatePresence initial={false} key={item?.title}>
            {indexExpanded === index ? (
              <motion.div
                className={styles.carousel}
                initial="hidden"
                animate="open"
                exit="exit"
                variants={{
                  hidden: {
                    opacity: 0,
                    top: 0,
                    transform: !isTablet ? 'translateX(100px)' : 'translateX(0px)',
                    transition: { duration: 0.2, delay: 0.5 },
                  },
                  open: {
                    opacity: 1,
                    top: 0,
                    transform: !isTablet ? 'translateX(0px)' : 'translateX(0px)',
                    transition: {
                      delay: 0.2, duration: 0.4,
                    },
                  },
                  exit: {
                    position: 'absolute', opacity: 0, top: 0, transition: { duration: 0.1 },
                  },
                }}
              >
                <CarouselBlock
                  medias={list[indexExpanded]?.data?.medias}
                  theme={theme}
                />
              </motion.div>
            ) : null}
          </AnimatePresence>
        )) : null}
      </div>
    </div>
  );
}

AccordionDynamicBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      medias: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        description: PropTypes.string,
      })),
    }),
  })),
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
};

AccordionDynamicBlock.defaultProps = {
  title: '',
  subtitle: '',
  list: [],
  theme: 'light',
};

export default AccordionDynamicBlock;
