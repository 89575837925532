import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

// Utils
import { cn } from 'utils';
import srcSet from '../../../src/utils/srcSet';

// Hooks
import useCarousel from '../../../src/hooks/useCarousel';

// Styles
import styles from './CarouselBlock.module.css';

// Components
import { Picto } from '../Picto/Picto';
import CarouselNavButton from '../CarouselNavButton/CarouselNavButton';

const Lightbox = dynamic(() => import('../../organisms/Lightbox/Lightbox'), { ssr: false });

function CarouselBlock({
  medias, theme, className, size,
}) {
  const { t } = useTranslation();
  const {
    activeLists,
    emblaRef,
    scrollPrev,
    scrollNext,
    prevBtnEnabled,
    nextBtnEnabled,
  } = useCarousel(medias || [], {
    emblaOptions: {
      containScroll: true,
      dragFree: true,
      loop: false,
    },
  });

  return (
    <div className={cn([styles.carousel, styles[size]])}>
      {activeLists?.length ? (
        <Lightbox
          images={activeLists}
          className={styles.carouselLightbox}
        >
          {(handleClickImage) => (
            <div
              className={cn([styles.carouselWrapper, styles[theme], className, 'carousel'])}
              ref={emblaRef}
            >
              <div className={styles.carouselContainer}>
                {activeLists.map((media, index) => (
                  <button
                    type="button"
                    key={index}
                    className={cn([styles.carouselSlide, 'carouselSlide'])}
                    onClick={() => handleClickImage(index)}
                    aria-label={media?.description || media?.credit || t('carouselBlock.labelImage', { index, total: activeLists?.length })}
                  >
                    { media?.url ? (
                      <img
                        loading="lazy"
                        src={`${media.url}?w=1440&format=webp&q=75`}
                        alt={media?.credit}
                        sizes="(max-width: 1024px) 75vw,
                                                   50vw"
                        srcSet={srcSet(media.url)}
                      />
                    ) : null}
                  </button>
                ))}
              </div>
            </div>
          )}
        </Lightbox>
      ) : null}
      {!prevBtnEnabled && !nextBtnEnabled
        ? null
        : (
          <div className={styles.navigationContainer}>
            <div className={styles.carouselNav}>
              <CarouselNavButton
                name={t('carouselBlock.labelPrev')}
                onClick={scrollPrev}
                isDisabled={!prevBtnEnabled}
              >
                <Picto className={styles.carouselButtonIcon} icon="arrowLeft" />
              </CarouselNavButton>
              <CarouselNavButton
                type="button"
                name={t('carouselBlock.labelNext')}
                onClick={scrollNext}
                isDisabled={!nextBtnEnabled}
              >
                <Picto className={styles.carouselButtonIcon} icon="arrowRight" />
              </CarouselNavButton>
            </div>
          </div>
        )}
    </div>
  );
}

CarouselBlock.propTypes = {
  medias: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
    credit: PropTypes.string,
  })),
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  className: PropTypes.string,
  size: PropTypes.oneOf(['compact', 'standard', '']),
};

CarouselBlock.defaultProps = {
  medias: [],
  theme: 'light',
  className: '',
  size: 'standard',
};

export default CarouselBlock;
